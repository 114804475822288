import React from "react";
import Seo from "../components/seo";
import termsPdf from "../pdfs/Brico_Legal Notice v1.0.pdf";
const LegalNotice = () => {
  return (
    <>
      <Seo title="Legal Notice" description="Legal Notice for Evistamp" />
      <div>
        <iframe className="w-full h-screen"  src={termsPdf} />
      </div>
    </>
  );
};
export default LegalNotice;
